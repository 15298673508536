//
// Form editors.scss
//

// Tinymce 

.tox {
    .tox-menubar, .tox-edit-area__iframe, .tox-statusbar{
      background-color: $card-bg !important;
    }
    .tox-mbtn{
      color: $gray-700 !important;
        &:hover:not(:disabled):not(.tox-mbtn--active){
            background-color: $gray-300 !important;
        }
    }
    .tox-tbtn{
        &:hover{
            background-color: $gray-300 !important;
        }
    }

    .tox-toolbar, .tox-toolbar__overflow, .tox-toolbar__primary{
      background-color: $card-bg !important;
    }

    .tox-tbtn{
      color: $gray-700 !important;
      svg{
        fill: $gray-700 !important;
      }
    }

    .tox-edit-area__iframe{
      background-color: $card-bg !important;
    }
  
    .tox-statusbar a, .tox-statusbar__path-item, .tox-statusbar__wordcount{
      color: $gray-700 !important;
    }
}

// Summernote

.note-editor{
    &.note-frame {
      border: 1px solid $gray-200;
      box-shadow: none;
      margin: 0;
  
      .note-statusbar {
        background-color: $gray-200;
        border-top: 1px solid $gray-200;
      }

      .note-editing-area{
        .note-editable, .note-codable {
          border: none;
          color: $gray-600;
          background-color: transparent;
        }
      }
  
    }
  }

  .note-btn-group{
    .note-btn{
      background-color: $gray-200 !important;
      border-color: $gray-200 !important;
    }
  }
  
  .note-status-output {
    display: none;
  }
  
  .note-editable {
  
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  
  .note-popover .popover-content .note-color .dropdown-menu,
  .card-header.note-toolbar .note-color .dropdown-menu {
      min-width: 344px;
  }
  
  .note-popover{
    border-color: $gray-300;
  }
  
  .note-popover .popover-content, 
  .card-header.note-toolbar{
    background-color: $gray-200;
  }

  .note-editable {
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    padding: $input-padding-y $input-padding-x;
  
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  